/* -----------map-topBar----------- */
.map-topBar {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  position: absolute;
  top: 20px;
  left: 10px;
  z-index: 1;
  font-weight: 400;
  font-size: 16px;
}
.selectedMapTab {
  color: #0984e3 !important;
}
.map-topBar .undo {
  padding: 8px;
  border-radius: 100%;
  background-color: #fff;
  width: fit-content;
  margin: 0px;
  line-height: 0px;
  cursor: pointer;
}

.map-topBar .redo {
  padding: 8px;
  border-radius: 50px;
  background-color: #fff;
  margin: 0px;
  line-height: 0px;
  cursor: pointer;
}
.map-topBar .ruler {
  padding: 8px;
  border-radius: 50px;
  background-color: #fff;
  width: fit-content;
  margin: 0px;
  line-height: 0px;
  cursor: pointer;
}
.map-topBar .rulerDropdown .dropdown-item:active {
  background: white !important;
  color: #414141;
}

.map-topBar .rulerDropdown .rulerItem {
  color: #0984e3;
}

.map-topBar .pv-area {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  padding: 6px 15px;
  border-radius: 27px;
  background-color: #fff;
  width: fit-content;
  cursor: pointer;
}
.map-topBar .addPVarea .addPVareaToggle {
  border: none !important;
}
.map-topBar .addPVarea .addPVareaDropdownItems {
  display: flex;
  align-items: center;
  gap: 10px;
}
.map-topBar .addPVareaDropdownItemsActive {
  color: #0984e3;
}
.map-topBar .addPVarea .dropdown-item:active {
  background: white !important;
  color: #414141;
}
.map-topBar .detection_point {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  padding: 6px 15px;
  border-radius: 27px;
  background-color: #fff;
  width: fit-content;
  cursor: pointer;
}
.map-topBar .exclude_area {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  padding: 6px 15px;
  border-radius: 27px;
  background-color: #fff;
  width: fit-content;
  cursor: pointer;
}
.map-topBar .excludeDropdown .dropdown-item:active {
  background: white !important;
  color: #414141;
}
.map-topBar .Search_on_map .searchAddresssimage {
  position: absolute;
  z-index: 1;
  top: 15px;
  margin-left: 10px;
}

.map-topBar .Search_on_map .searchAddresss {
  position: relative;
  border: none;
  padding: 5px 15px 5px 0px;
  padding-left: 35px;
  border-radius: 27px;
  background-color: #fff;
  width: 250px;
}
.map-topBar .Search_on_map .searchAddresss::placeholder {
  color: black;
}
.map-topBar .Search_on_map .searchAddresss:focus,
.map-topBar .Search_on_map .searchAddresss:active {
  border: none;
  outline: none;
}
/* --------------------- */
/* ---------side_bar_div------------ */
.side_bar_div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  line-height: normal; /* Use normal instead of a specific value */
  margin: 0px;
  width: 100%;
}

.side_bar_div .nonActiveType, 
.side_bar_div .activeType {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  background-color: #f5f5f5;
  padding: 15px 12px;
  cursor: pointer;
  flex: 1;
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Add ellipsis for overflowing text */
  height: 50px; /* Set a fixed height for all tabs */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* For very long German words, consider adding a class for German-specific tabs */
html[lang="de"] .side_bar_div .nonActiveType,
html[lang="de"] .side_bar_div .activeType {
  font-size: 13px; /* Slightly smaller font for German */
}

/* For very small screens, stack the tabs vertically */
@media (max-width: 480px) {
  .side_bar_div {
    flex-direction: column;
    width: 100%;
  }
  
  .side_bar_div .nonActiveType,
  .side_bar_div .activeType {
    width: 100%;
    min-width: unset;
  }
}

/* --------------------- */
/* ----------tiltedPVArea ----------- */
.tiltedNothingToShow {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
}
.tiltedNothingToShow img {
  margin: auto;
  width: 90%;
  height: 150px;
  object-fit: contain;
  display: block;
}
.tiltedNothingToShow strong {
  text-align: center;
  display: block;
  font-weight: 700;
  font-size: 20px;
}
.tiltedNothingToShow small {
  text-align: center;
  display: block;
  font-weight: 400;
  font-size: 16px;
}
.tiltedNothingToShow small span {
  font-weight: 600;
}
.tiltedPVarea .accordion-button {
  justify-content: space-between;
  height: 50px;
  align-items: center;
}
.tiltedPVarea .accordion-button::after {
  display: none;
  /* box-shadow: none;
  border-color: rgba(0, 0, 0, 0.125); */
}
.tiltedPVarea .accordion-button:focus {
  /* box-shadow: none !important; */
  background-color: #fff !important;
}
/* .tiltedPVarea .dropdown-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: none;
  background-color: #f5f5f5 !important;
}
.tiltedPVarea .dropdown-toggle .projectName {
  font-size: 18px;
  font-weight: 500;
}
.tiltedPVarea .dropdown-toggle .collapseBtn {
  font-size: 16px;
  font-weight: 500;
}

.tiltedPVarea .dropdown-toggle:focus {
  background-color: #e6f3fc !important;
  border-bottom: 2px solid #0984e3;
}
.tiltedPVarea .dropdown-toggle p {
  line-height: 0px;
  margin: 0px;
}
.tiltedPVarea .dropdownMenu p {
  font-size: 18px;
  font-weight: 500;
  line-height: 0px;
  text-transform: uppercase;
}
.tiltedPVarea .dropdownMenu small {
  font-size: 16px;
  font-weight: 500;
  line-height: 0px;
  color: #0984e3;
} */

.tiltedPVarea .coolinput {
  display: flex;
  flex-direction: column;
  width: fit-content;
  position: static;
  max-width: 200px;
}

.tiltedPVarea .coolinput label.text {
  font-size: 14px;
  font-weight: 400;
  position: relative;
  top: 0.5rem;
  margin: 0 0 0 3px;
  padding: 0 4px;
  background: #fff;
  width: fit-content;
  border-radius: 4px;
  color: #6d7984;
}

.tiltedPVarea .coolinput .input {
  padding: 11px 10px;
  font-size: 0.75rem;
  border-radius: 4px;
  border: 1px solid #858585;
  background: #fff;
  height: 35px;
  width: 155px;
}

.disabledInput {
  background-color: rgb(237, 234, 234) !important;
}
.tiltedPVarea .coolinput .input::-webkit-outer-spin-button,
.tiltedPVarea .coolinput .input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.tiltedPVarea .coolinput .input {
  -moz-appearance: textfield;
}

.tiltedPVarea .coolinput .input:focus {
  outline: none;
}

.tiltedPVarea .switch {
  font-size: 17px;
  position: relative;
  display: inline-block;
  width: 36px;
  height: 18px;
}

.tiltedPVarea .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.tiltedPVarea .slider {
  position: absolute;
  cursor: pointer;
  inset: 0;
  border: 2px solid #414141;
  border-radius: 50px;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.tiltedPVarea .slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 3px;
  bottom: 1px;
  background-color: black;
  border-radius: inherit;
  transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
}

.tiltedPVarea .switch input:checked + .slider:before {
  transform: translateX(0.9em);
  background-color: #13e341;
}
.tiltedPVarea .default {
  border-radius: 4px;
  border: 1px solid #858585;
  background: #fff;
  height: 35px;
  width: 155px;
}
.tiltedPVarea .floatingLable {
  font-size: 14px;
  font-weight: 400;
  position: relative;
  bottom: 47.5px;
  margin-left: 7px;
  color: #6d7984;
  background: #fff !important;
  width: fit-content;
}
.tiltedPVarea .defaultText {
  font-size: 15px !important;
  font-weight: 400 !important;
}

/* --------------------- */

/* ----------dectectionPoints ----------- */
.detectionNothingToShow {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
}
.detectionNothingToShow img {
  margin: auto;
  width: 90%;
  height: 150px;
  object-fit: contain;
  display: block;
}
.detectionNothingToShow strong {
  text-align: center;
  display: block;
  font-weight: 700;
  font-size: 20px;
}
.detectionNothingToShow small {
  text-align: center;
  display: block;
  font-weight: 400;
  font-size: 16px;
}
/* .DetectionPvAreaPage .dropdown-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: none;
  background-color: #f5f5f5 !important;
} */
.DetectionPvAreaPage .accordion-button {
  justify-content: space-between;
}
.DetectionPvAreaPage .accordion-button::after {
  display: none;
  /* box-shadow: none;
  border-color: rgba(0, 0, 0, 0.125); */
}
.DetectionPvAreaPage .accordion-button:focus {
  /* box-shadow: none !important; */
  background-color: #fff !important;
}
/* .DetectionPvAreaPage .dropdown-toggle .projectName {
  font-size: 18px;
  font-weight: 500;
} */
/* .DetectionPvAreaPage .dropdown-toggle .collapseBtn {
  font-size: 16px;
  font-weight: 500;
} */

/* .DetectionPvAreaPage .dropdown-toggle:focus {
  background-color: #e6f3fc !important;
  border-bottom: 2px solid #0984e3;
} */
/* .DetectionPvAreaPage .dropdown-toggle p {
  line-height: 0px;
  margin: 0px;
} */
/* .DetectionPvAreaPage .dropdownMenu p {
  font-size: 18px;
  font-weight: 500;
  line-height: 0px;
  text-transform: uppercase;
}
.DetectionPvAreaPage .dropdownMenu small {
  font-size: 16px;
  font-weight: 500;
  line-height: 0px;
  color: #0984e3;
} */

.DetectionPvAreaPage .coolinput {
  display: flex;
  flex-direction: column;
  width: fit-content;
  position: static;
  max-width: 200px;
}

.DetectionPvAreaPage .coolinput label.text {
  font-size: 14px;
  font-weight: 400;
  position: relative;
  top: 0.5rem;
  margin: 0 0 0 7px;
  padding: 0 4px;
  background: #fff;
  width: fit-content;
  border-radius: 4px;
  color: #6d7984;
}

.DetectionPvAreaPage .coolinput .input {
  padding: 11px 10px;
  font-size: 0.75rem;
  border-radius: 4px;
  background: #fff;
  height: 35px;
  width: 155px;
}
.DetectionPvAreaPage .coolinput .input:focus {
  outline: none;
}

.DetectionPvAreaPage .coolinput .input {
  -moz-appearance: textfield;
}
.DetectionPvAreaPage .coolinput .input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.DetectionPvAreaPage .addAltitude {
  height: 35px;
  width: 155px;
  border: 2px solid #0984e3;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
}
.DetectionPvAreaPage .addAltitude p {
  line-height: 0px;
  font-weight: 400;
  font-size: 16px;
  margin: auto;
  color: #0984e3;
}
/* --------------------- */

/* ----------exclude area ----------- */

.verticalNothingToShow {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
}
.verticalNothingToShow img {
  margin: auto;
  width: 90%;
  height: 150px;
  object-fit: contain;
  display: block;
}
.verticalNothingToShow strong {
  text-align: center;
  display: block;
  font-weight: 700;
  font-size: 20px;
}
.verticalNothingToShow small {
  text-align: center;
  display: block;
  font-weight: 400;
  font-size: 16px;
}
.VerticalPvAreaPage .accordion-button {
  justify-content: space-between;
  height: 50px;
  align-items: center;
}
.VerticalPvAreaPage .accordion-button::after {
  display: none;
  /* box-shadow: none;
  border-color: rgba(0, 0, 0, 0.125); */
}
.VerticalPvAreaPage .accordion-button:focus {
  /* box-shadow: none !important; */
  background-color: #fff !important;
}
/* .VerticalPvAreaPage .dropdown-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: none;
  background-color: #f5f5f5 !important;
}
.VerticalPvAreaPage .dropdown-toggle .projectName {
  font-size: 18px;
  font-weight: 500;
}
.VerticalPvAreaPage .dropdown-toggle .collapseBtn {
  font-size: 16px;
  font-weight: 500;
}

.VerticalPvAreaPage .dropdown-toggle:focus {
  background-color: #e6f3fc !important;
  border-bottom: 2px solid #0984e3;
}
.VerticalPvAreaPage .dropdown-toggle p {
  line-height: 0px;
  margin: 0px;
}
.VerticalPvAreaPage .dropdownMenu p {
  font-size: 18px;
  font-weight: 500;
  line-height: 0px;
  text-transform: uppercase;
}
.VerticalPvAreaPage .dropdownMenu small {
  font-size: 16px;
  font-weight: 500;
  line-height: 0px;
  color: #0984e3;
} */
.VerticalPvAreaPage .coolinput {
  display: flex;
  flex-direction: column;
  width: fit-content;
  position: static;
  max-width: 200px;
}

.VerticalPvAreaPage .coolinput label.text {
  font-size: 14px;
  font-weight: 400;
  position: relative;
  top: 0.5rem;
  margin: 0 0 0 2px;
  padding: 0 4px;
  background: #fff;
  width: fit-content;
  border-radius: 4px;
  color: #6d7984;
}
.disabledInput {
  background-color: rgb(237, 234, 234) !important;
}

.VerticalPvAreaPage .coolinput .input {
  padding: 11px 10px;
  font-size: 0.75rem;
  border-radius: 4px;
  border: 1px solid #858585;
  background: #fff;
  height: 35px;
  width: 155px;
}
.VerticalPvAreaPage .coolinput .input:focus {
  outline: none;
}
.VerticalPvAreaPage .coolinput .input[type="number"] {
  -moz-appearance: textfield;
}
.VerticalPvAreaPage .coolinput .input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.VerticalPvAreaPage .switch {
  font-size: 17px;
  position: relative;
  display: inline-block;
  width: 36px;
  height: 18px;
}

.VerticalPvAreaPage .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.VerticalPvAreaPage .slider {
  position: absolute;
  cursor: pointer;
  inset: 0;
  border: 2px solid #414141;
  border-radius: 50px;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.VerticalPvAreaPage .slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 3px;
  bottom: 1px;
  background-color: black;
  border-radius: inherit;
  transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
}

.VerticalPvAreaPage .switch input:checked + .slider:before {
  transform: translateX(0.9em);
  background-color: #13e341;
}
.VerticalPvAreaPage .default {
  border-radius: 4px;
  border: 1px solid #858585;
  background: #fff;
  height: 35px;
  width: 155px;
}
.VerticalPvAreaPage .floatingLable {
  font-size: 14px;
  font-weight: 400;
  position: relative;
  bottom: 50px;
  margin-left: 7px;
  color: #6d7984;
  background: #fff !important;
  width: fit-content;
}
.VerticalPvAreaPage .default p {
  color: #2f3f50 !important;
  font-size: 16px !important;
  font-weight: 400 !important;
}
.VerticalPvAreaPage .default .defaultText {
  font-size: 15px !important;
  font-weight: 400 !important;
}
/* --------------------- */
/* ----------Calculate Button----------- */
.CalculateButton {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  line-height: 0px !important;
  margin: 0px !important;
  gap: 10px !important;
  background-color: rgb(63, 241, 63) !important;
  border: none !important;
  border-radius: 39px !important;
  width: 350px;
}
.disabledCalculateButton {
  border: none !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  line-height: 0px !important;
  /* margin-top: 45px  !important; */
  gap: 10px !important;
  /* background-color: #858585 !important; */
  background-color: #ff6969 !important;
  border-radius: 39px !important;
  width: 350px;
}

/* --------------------- */
/* ---------Map Page Header------------ */
.mapPageHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px;
  
}
.mapPageHeader .moduleTypeOption{
  font-size: 13px;
}

.mapPageHeader .coolinput {
  margin: auto;
  display: flex;
  flex-direction: column;
  width: fit-content;
  position: static;
}

.mapPageHeader .coolinput label.text {
  font-size: 12px;
  font-weight: 400;
  position: relative;
  top: 0.5rem;
  margin: 0 0 0 4px;
  padding: 0 4px;
  background: #fff;
  width: fit-content;
  border-radius: 4px;
  color: #6d7984;
}
.disabledInput {
  background-color: rgb(237, 234, 234) !important;
}

.mapPageHeader .coolinput .input {
  padding: 11px 10px;
  font-size: 0.75rem;
  border-radius: 4px;
  border: 1px solid #858585;
  background: #fff;
  height: 35px;
  width: 210px;
}
.mapPageHeader .coolinput .input:focus {
  outline: none;
}
.mapPageHeader .coolinput .input[type="number"] {
  -moz-appearance: textfield;
}
.mapPageHeader .coolinput .input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mapPageHeader img {
  object-fit: contain;
}
.mapPageHeader .centerpart {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
}
.mapPageHeader .centerpart .dashboard {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  color: #2f3f50;
  font-weight: 400;
  font-size: 16px;
  padding: 2px 6px;
  background-color: #f5f5f5;
  border-radius: 4px 0px 0px 4px;
}
.mapPageHeader .centerpart p {
  color: #2f3f50;
  font-weight: 500;
  font-size: 16px;
  border-radius: 0px 4px 4px 0px;
  border: 1px solid #f5f5f5;
  padding: 2px 6px;
}
.mapPageHeader .headerButton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.mapPageHeader .headerButton .optionDropdown {
  border: none !important;
  text-decoration: none !important;
}
.mapPageHeader .headerButton .optionDropdown:active {
  border: none !important;
}
.mapPageHeader .headerButton .optionReload {
  font-weight: 400;
  font-size: 15px;
  line-height: 10px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  background-color: transparent !important;
  width: 90%;
  margin: auto;
  color: #000;
  padding: 10px 8px;
  border-radius: 8px;
}
/* .mapPageHeader .headerButton .optionReload:hover {
  background: none !important;
} */
/* .mapPageHeader .headerButton .optionReload:active {
  color: #414141;
  background: none !important;
} */
.mapPageHeader .headerButton .option2 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  font-weight: 500;
  font-size: 16px;
  border: 0.5px solid #858585;
  border-radius: 45px;
  line-height: 0px;
  height: 33px;
  margin: auto;
  min-width: 150px; /* Change from width to min-width */
  padding: 0 16px; /* Add horizontal padding */
}
html[lang="de"] .mapPageHeader .headerButton .option2 {
  font-size: 14px; /* Smaller font for German */
}
.mapPageHeader .headerButton .option2:focus {
  border: 0.5px solid #858585;
  border-radius: 45px;
}

.mapPageHeader .headerButton .option {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  font-weight: 500;
  font-size: 16px;
  border: 0.5px solid #858585;
  border-radius: 45px;
  line-height: 0px;
  height: 33px;
  margin: auto;
}
.mapPageHeader .headerButton .option:focus {
  border: 0.5px solid #858585;
  border-radius: 45px;
}
.mapPageHeader .headerButton .help {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  font-weight: 700;
  font-size: 16px;
  border: 0.5px solid #0984e3;
  border-radius: 45px;
  line-height: 0px;
  height: 33px;
  color: #0984e3;
  margin: auto;
}
.mapPageHeader .headerButton .help:focus {
  border: 0.5px solid #0984e3;
  border-radius: 45px;
}
/* --------------------- */

.Map-render {
  width: 100% !important;
  height: 100% !important;
}

.map-container {
  width: 100%;
  height: 89svh !important;
  position: relative;
}

.logo {
  width: 180px;
  object-fit: contain;
}
.Map-page-content {
  display: flex;
  padding: 0 16px;
  gap: 10px;
}

.Map-sidebar {
  width: 35%;
}
.marker-label {
  margin-left: 30px;
  margin-bottom: 25px;
  color: #fff !important;
}

@media screen and (max-width: 1425px) {
  .Map-sidebar {
    width: 40%;
  }
}
@media screen and (max-width: 1300px) {
  .Map-sidebar {
    width: 50%;
  }
}
/* ---------Tilted Tolltip------------ */
.TiltedTooltip {
  width: 40% !important;
  position: relative;
  left: 0px;
  right: 0px;
  margin: auto;
}

/* .TiltedTooltip:after {
  content: "";
  position: absolute;
  border: 2px solid #fff;
  right: 0px;
  width: 40px;
  height: 40px;
  top: calc(50%);
  background-color: red;
  transform: rotate(137deg);
  clip-path: polygon(0 0, 0% 100%, 100% 0);
} */

.TiltedTooltip .gotitBtn {
  padding: 8px 10px;
  margin: 0px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #daedfb;
}
.TiltedTooltip .gotitBtn span {
  font-size: 16px;
  font-weight: 700;
  color: #0984e3;
  background-color: #daedfb;
}
.TiltedTooltip .tiltedtooltipCheck {
  border: 1px solid #0984e3;
  padding: 7px 8px;
  border-radius: 8px;
  cursor: pointer;
  z-index: 100;
  display: flex;
  align-items: center;
  gap: 8px;
  color: #0984e3;
  font-size: 16px;
  font-weight: 400;
}
.TiltedTooltip .tiltedtooltipCheck .form-check-input:focus {
  box-shadow: none !important;
}
@media screen and (max-width: 820px) {
  .TiltedTooltip {
    width: 80% !important;
  }
}
@media screen and (max-width: 350px) {
  .TiltedTooltip {
    width: 100% !important;
  }
}
.custom-marker-lable {
  font-size: 14px !important;
  font-weight: 400 !important;
  background-color: #fff !important;
  padding: 3px !important;
  border-radius: 4px !important;
  margin-bottom: 50px !important;
}
.mapPageHeader .ChangeValuesBtn {
  width: 150px;
  border-radius: 8px;
  background-color: #0984e3;
  height: 32px;
  line-height: 0px;
  color: #fff;
}
.mapPageHeader .ChangeValuesBtn:hover{
  background-color: #0984e3 !important;
  color: #fff !important;
}
.mapPageHeader .SetDeafultValueBtn {
  width: 150px;
  border: 1px solid #0984e3;
  border-radius: 8px;
  height: 32px;
  line-height: 0px;
  color: #0984e3;
}
.mapPageHeader .SetDeafultValueBtn:hover {
  border: 1px solid #0984e3 !important;
  color: #0984e3 !important;
}

