.App {
  text-align: center;
}
.bgF4f4fb {
  background-color: #f4f4fb !important;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import url("./pages/Map/MapPage.css");
/* <Footer> */
.footerText {
  color: var(--Grayscale-32, #858585);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
@media screen and (max-width: 576px) {
  .privacyPolicydiv {
    width: 100%;
  }
}
/* <navbar> */
.navbar {
  z-index: 100;
  background-color: #fff;
  box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.05);
  /* position: fixed !important; */
  /* top: 0px !important; */
}
.navDropDown {
  color: #2f3f50;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
.navDropDown:focus {
  background-color: #fff !important;
  color: #2f3f50 !important;
}
.navDropDown:active {
  background-color: #fff !important;
  color: #2f3f50 !important;
}
.logo {
  width: 180px;
  object-fit: contain;
}
.solarCalc {
  color: #2f3f50;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-top: 10px;
}
.myProfile {
  color: #2f3f50;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  margin-top: 20px;
  line-height: 24px;
  text-transform: capitalize;
}
/* <dashboard> */
.dashboardDiv {
  background: url("./images/Solar\ 1.png") no-repeat;
  background-size: cover;
  height: 100vh;
}
.dashHeading {
  color: #fff;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.44px;
}
.imgDropdown {
  margin-bottom: 12px;
  border: none !important;
  padding: 0px !important;
}
.dashButton {
  border: 1px solid #2f3f50 !important;
  width: 180px;
  height: 44px;
  border-radius: 4px !important;
  margin-left: 10px !important;
  color: #2f3f50 !important;
  font-size: 15px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
.dashButton:hover {
  color: #fff !important;
  background-color: #2f3f50 !important;
}
.dashCard {
  width: 450px;
  height: 90px;
  border-radius: 8px;
}
.cardText {
  color: #2f3f50;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
}
@media screen and (max-width: 520px) {
  .dashCard {
    width: 400px;
    margin: auto !important;
    text-align: center;
  }
}
@media screen and (max-width: 991px) {
  .dashboardDiv {
    height: 100% !important;
  }
}
@media screen and (max-width: 769px) {
  .dashboardDiv {
    height: 100% !important;
  }
}
.logout {
  width: 180px !important;
  height: 44px !important;
  border-radius: 4px !important;
  background: #2f3f50 !important;
  color: #fff !important;
  font-size: 15px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}

/* <new project> */
.backToDashboard {
  color: #2f3f50;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}
.newProjects {
  color: var(--primary-text-headings, #2f3f50);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.96px;
}
.newProjectLabel {
  color: #2f3f50;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.newProjectInput {
  width: 500px;
  height: 44px;
  border-radius: 4px;
  border: 0.5px solid #858585;
}
.upload-container {
  width: 500px;
  height: 100px;
  text-align: center;
  position: relative;
  overflow: hidden;
  display: inline-block;
  border-radius: 2px;
  border: 0.5px solid #858585;
}
.register-upload-container {
  width: 400px;
  height: 109px;
  text-align: center;
  position: relative;
  overflow: hidden;
  display: inline-block;
  border-radius: 4px;
  border: 1px solid #2f3f50;
}

.file-input {
  position: absolute;
  font-size: 100px;
  opacity: 0;
  right: 0;
  top: 0;
}

.upload-text {
  display: block;
  padding: 10px;
  cursor: pointer;
  color: #2f3f50;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  border-radius: 5px;
}
.wikipediaLink {
  color: #599afc;
  transition: color 0.3s;
}
.wikipediaLink:hover {
  color: #050c9c;
}
.upload-icon {
  display: block;
  margin: auto;
  content: url("./images/icon-park-outline_add-picture.png");
}
.newProjectButton {
  width: 500px !important;
  height: 44px !important;
  border-radius: 4px !important;
  background-color: #2f3f50 !important;
  color: #fff !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 30px;
}

@media screen and (max-width: 576px) {
  .newProjectInput {
    width: 300px;
  }
  .upload-container {
    width: 300px;
  }
  .newProjectButton {
    width: 300px !important;
  }
}

/* <active> */
.activeSearch {
  border-radius: 4px;
  border: 0.5px solid #2f3f50;
  width: 220px;
  height: 30px;
  z-index: -1;
  border-radius: 4px;
  padding-left: 38px;
}
.activeSearch:focus {
  outline: none;
}
.searchDiv {
  position: relative;
  display: inline-block;
}
.searchIcon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  height: 20px;
  width: 20px;
}
.activeCard {
  border-radius: 8px;
  background: #fff;
  box-shadow: 2px 2px 35px 0px rgba(0, 0, 0, 0.08);
  height: 274px;
  width: 100%;
}

.reportListDropdownMenu {
  height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px !important;
}

.reportListDropdownList {
  background-color: #fff !important;
  color: #2f3f50 !important;
  border: 1px solid #2f3f50 !important;
  border-radius: 8px !important;
}
/* .reportListDropdownList span {
  color: #2f3f50 !important;
  font-size: 13px;
} */
.reportListDropdownList:hover {
  box-shadow: 2px 2px 25px 0px rgba(0, 0, 0, 0.04) !important;
  border-color: #0984e3 !important;
}
.reportListDropdownList p {
  font-size: 14px;
  line-height: 0px;
  font-weight: 500;
}
.activeOPenProjects {
  color: #fff !important;
  width: 138px;
  height: 32px;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.nothingToShow {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: 40px;
}
.activeCalculated {
  color: #2f3f50 !important;
  min-width: 138px; /* Change from width to min-width */
  height: 32px;
  background-color: transparent !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  border-radius: 4px !important;
  border: 1px solid #2f3f50 !important;
  padding: 0 12px; /* Add horizontal padding */
  white-space: nowrap; /* Prevent text wrapping */
}

html[lang="de"] .activeCalculated {
  min-width: 160px; /* Wider for German */
}

@media screen and (max-width: 768px) {
  .activeCalculated {
    width: 100%;
  }
  .activeOPenProjects {
    width: 100%;
  }
}
@media screen and (max-width: 576px) {
  .activeSearch {
    width: 100% !important;
  }
  .searchDiv {
    width: 100%;
  }
}

.dropdown-toggle::after {
  display: none !important;
}
.projectName {
  color: #2f3f50;
  font-size: 15px;
  font-weight: 500;
  line-height: normal;
  /* text-transform: uppercase; */
}
.activeDropdown {
  border: none !important;
}
.activeDropdown:focus {
  border: none !important;
}
.activeDropDownItem {
  color: #2f3f50 !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px !important;
}
.activeDropDownItem:focus {
  background-color: #fff !important;
}
/* <my reports> */
.reportsCard {
  border-radius: 8px;
  background: #fff;
  box-shadow: 2px 2px 35px 0px rgba(0, 0, 0, 0.08);
  height: 200px;
}
.reportHome {
  color: #2f3f50;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
  max-width: none; /* Remove max-width constraint */
  display: inline-block; /* Better positioning */
  margin-bottom: 5px; /* Add space between label and line */
  padding-right: 10px; /* Add space to the right */
}
.reportHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
}

.reportDivider {
  width: 100%;
  height: 1px;
  background-color: #e0e0e0;
  margin: 5px 0;
}
.reportID {
  color: #2f3f50;
  font-size: 14px;
  font-weight: 400;
  margin: 0px;
  height: auto; /* Changed from fixed height */
  display: flex; /* Use flex to properly align content */
  align-items: center;
  white-space: nowrap; /* Prevent wrapping */
}

html[lang="de"] .reportID {
  font-size: 13px; /* Wider for German */
}


.reportsOpenProject {
  border-radius: 8px;
  border: 1px solid #0984e3;
  background-color: #0984e3 !important;
  width: 80% !important;
  color: #fff !important;
  font-size: 16px;
  height: 32px;
  font-style: normal;
  font-weight: 500;
  display: flex;
  justify-content: center;
  /* line-height: 20px; */
}
.reportsDownloadProject {
  height: 32px;
  border-radius: 8px !important;
  border: 1px solid #0984e3 !important;
  min-width: 165px !important; 
  width: auto !important;
  color: #0984e3;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  padding: 0 12px;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* html[lang="de"] .reportsDownloadProject {
  min-width: 160px !important; 
} */
.reportCardText {
  color: var(--Grayscale-64, #5c5c5c);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.reportCardText span {
  font-size: 14px;
}
.reportModal .modal-content {
  height: 400px;
  width: 350px;
  margin: auto;
  overflow-y: auto;
  overflow-x: hidden;
}
/* <Registration> */
.regEN {
  color: var(--Primary-White, #fff);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  border-radius: 2px;
  background: var(--primary-text-headings, #2f3f50);
  cursor: pointer;
}
.regDU {
  color: var(--primary-text-headings, #2f3f50);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
}
.regLetsStart {
  color: var(--primary-text-headings, #2f3f50);
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 1.2px;
}
.reglogin {
  width: 253px !important;
  height: 44px !important;
  border-radius: 4px !important;
  background: #2f3f50 !important;
  color: #fff !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
.reg {
  width: 253px !important;
  height: 44px !important;
  border-radius: 4px !important;
  border: 1px solid #2f3f50 !important;
  color: #2f3f50 !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
/* <login> */
.loginWelcome {
  color: var(--primary-text-headings, #2f3f50);
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 1.2px;
}
.loginInput {
  width: 400px;
  height: 44px;
  border-radius: 4px;
  border: 1px solid #2f3f50;
  color: #858585;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 10px;
}
.loginOR {
  color: #2f3f50;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.googleLogin {
  width: 400px !important;
  height: 44px !important;
  border-radius: 4px !important;
  border: 1px solid var(--primary-text-headings, #2f3f50) !important;
  color: var(--primary-text-headings, #2f3f50) !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  text-align: left !important;
}
.facebookLogin {
  text-align: left !important;
  width: 400px !important;
  height: 44px !important;
  border-radius: 4px !important;
  border: 1px solid var(--primary-text-headings, #2f3f50) !important;
  color: var(--primary-text-headings, #2f3f50) !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
.login {
  width: 400px !important;
  height: 44px !important;
  border-radius: 4px !important;
  background: #2f3f50 !important;
  color: #fff !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  margin-top: 50px;
}
.forgetpassword {
  color: #e31b1b;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-transform: uppercase;
  cursor: pointer;
}
/* <Register> */
.error {
  font-size: 15px;
  color: #e31b1b !important;
}
.registerFname {
  width: 190px;
  height: 44px;
  border-radius: 4px;
  border: 1px solid #2f3f50;
  color: #858585;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 10px;
}
.registerLname {
  width: 190px;
  height: 44px;
  border-radius: 4px;
  border: 1px solid #2f3f50;
  color: #858585;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 10px;
}
.registerCont {
  width: 400px !important;
  height: 44px !important;
  border-radius: 4px !important;
  background: #2f3f50 !important;
  color: #fff !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}
/* <projectOverview> */
.calculatingPercentage {
  color: #2f3f50;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.StatusBar {
  position: relative !important;
  height: 26px !important ;
  border: 1px solid #adadad !important;
  border-radius: 0px !important;
  background-color: #fff !important;
}
.progress-bar {
  background-color: #13e341 !important;
}
/* <unpaid project> */
.centerpart {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
}
.centerpart .dashboard {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  color: #2f3f50;
  font-weight: 400;
  font-size: 16px;
  padding: 2px 6px;
  background-color: #f5f5f5;
  border-radius: 4px 0px 0px 4px;
}
.centerpart p {
  color: #2f3f50;
  font-weight: 500;
  font-size: 16px;
  border-radius: 0px 4px 4px 0px;
  border: 1px solid #f5f5f5;
  padding: 2px 6px;
}
.detectionPoint {
  text-align: center;
  color: #5c5c5c;
  font-size: 19.169px;
  font-style: normal;
  font-weight: 500;
  line-height: 23.961px;
}
.Glarediagram {
  color: #2f3f50;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 83.333% */
  text-transform: uppercase;
}
.PO-clickHere {
  cursor: pointer;
}
.PO-MYHOME {
  color: #2f3f50;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}
.PO-glare {
  color: #2f3f50;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 83.333% */
  text-transform: uppercase;
}
.PO-text {
  color: #2f3f50;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.PO-table {
  width: 100%;
  border: 0.5px solid #adadad;
}
.pobadge-strong {
  border-radius: 20px !important;
  border: 1px solid #ff7a00 !important;
  background-color: #ffe4cc !important;
  width: 84px !important;
  height: 20px !important;
  color: #ff7a00 !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  padding: 3px 0px !important;
}
.pobadge-mild {
  border-radius: 20px !important;
  border: 1px solid #e39d00 !important;
  background-color: #fff2d6 !important;
  width: 84px !important;
  height: 20px !important;
  color: #e39d00 !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  padding: 3px 0px !important;
}
.pobadge-extreme {
  border-radius: 20px !important;
  border: 1px solid #f00 !important;
  background-color: #fcc !important;
  width: 84px !important;
  height: 20px !important;
  color: #f00 !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  padding: 3px 0px !important;
}
.pobadge-none {
  border-radius: 20px !important;
  border: 1px solid #2ca62d !important;
  background-color: #ddf1dd !important;
  width: 84px !important;
  height: 20px !important;
  color: #2ca62d !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  padding: 3px 0px !important;
}
.PO-table-heading {
  color: #2f3f50;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.PO-table-data {
  color: #2f3f50;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.PO-table-row {
  height: 36px;
}
.PO-table-heading-row {
  height: 56px;
}
.PO-date {
  color: #5c5c5c;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.POdata {
  color: #5c5c5c;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.FullReportCard {
  width: 300px;
  height: 555px;
  border-radius: 8px;
  border: 1px solid #0984e3;
  background: #fff;
  box-shadow: 2px 2px 35px 0px rgba(0, 0, 0, 0.08);
}
.fullProfileButton {
  position: relative;
  top: 15px;
}
.glareLine {
  width: 500px !important;
}
@media screen and (max-width: 992px) {
  .FullReportCard {
    margin: auto;
    height: 400px;
    width: 480px;
  }
  .fullProfileButton {
    top: 15px;
  }
}
@media screen and (max-width: 550px) {
  .glareLine {
    width: 400px !important;
  }
  .FullReportCard {
    margin: auto;
    height: 320px;
    width: 100%;
  }
}
@media screen and (max-width: 400px) {
  .glareLine {
    width: 350px !important;
  }
}
@media screen and (max-width: 350px) {
  .glareLine {
    width: 300px !important;
  }
  .newProjects {
    font-size: 24px;
    letter-spacing: 0.72px;
    margin-bottom: 15px;
  }
  .PO-table-data {
    font-size: 10px;
    line-height: normal;
  }
  .PO-table-heading {
    font-size: 10px;
    line-height: normal;
  }
  .pobadge-strong {
    width: 54px !important;
    height: 15px !important;
    font-size: 10px !important;
    font-weight: 400 !important;
    border: 0.7px solid #ff7a00 !important;
  }
  .pobadge-mild {
    border: 0.7px solid #e39d00 !important;
    width: 54px !important;
    height: 15px !important;
    font-size: 10px !important;
    font-weight: 400 !important;
  }
  .pobadge-extreme {
    border: 0.7px solid #f00 !important;
    width: 54px !important;
    height: 15px !important;
    font-size: 10px !important;
    font-weight: 400 !important;
  }
  .pobadge-none {
    border: 0.7px solid #2ca62d !important;
    width: 54px !important;
    height: 15px !important;
    font-size: 10px !important;
    font-weight: 400 !important;
  }
}
.fullReport {
  color: #2f3f50;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}
.PO-card-text {
  color: #2f3f50;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.PO-card-data {
  color: #2f3f50;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.PO-card-purchaseFullReport {
  width: 250px !important;
  padding: 0px !important;
  border-radius: 8px !important;
  border: 1px solid #0984e3 !important;
  background: #0984e3 !important;
  color: #fff !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px !important;
}
.PO-card-seeExample {
  width: 250px !important;
  border-radius: 8px !important;
  border: 1px solid #0984e3 !important;
  color: #0984e3 !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  padding: 0px !important;
}
.paid-downloadPDF {
  width: 500px !important;
  padding: 0px !important;
  display: block !important;
  text-align: center !important;
  color: #fff !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  border-radius: 8px !important;
  border: 1px solid #0984e3 !important;
  background: #0984e3 !important;
  margin: 10px 0px !important;
  text-decoration: none !important;
}
.pdf-downloadPDF {
  text-align: center;
  width: 500px !important;
  padding: 0px !important;
  color: #fff !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  border-radius: 8px !important;
  border: 1px solid #0984e3 !important;
  background: #0984e3 !important;
  margin-bottom: 0px;
  height: 30px;
}
.mapDasboard {
  width: 116px;
  height: 30px;
  border-radius: 4px 0px 0px 4px;
  background: #f5f5f5;
  cursor: pointer;
  color: #2f3f50;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.actProject {
  text-align: center;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  width: 216px;
  height: 30px;
  border-radius: 0px 4px 4px 0px;
  border: 1px solid #ebebeb;
  color: #2f3f50;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.mapNav {
  background-color: #fff;
  padding: 5px 0px;
}
.helpbtn {
  cursor: pointer;
  display: flex;
  width: 78px;
  justify-content: center;
  align-items: center;
  height: 30px !important;
  border-radius: 4px !important;
  border: 1px solid #0984e3 !important;
  color: #0984e3 !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px !important;
}
.startSimulationbtn {
  cursor: pointer;
  display: flex;
  width: 150px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #13e341;
  background: #f5fff7;
  color: #13e341;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
/* <LandingNav> */
.landingLog {
  padding: 8px 20px !important;
  border-radius: 4px !important;
  border: 1px solid var(--primary-text-headings, #2f3f50) !important;
  background: var(--Primary-White, #fff) !important;
  color: var(--primary-text-headings, #2f3f50) !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 0px !important;
  margin-left: 20px;
}
.landingSign {
  padding: 8px 20px !important;
  border-radius: 4px !important;
  background: var(--Primary-Button, #0984e3) !important;
  color: var(--Primary-White, #fff) !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 0px !important;
  margin-left: 20px;
}
/* <LandingPAge> */
.lanAppName {
  color: var(--primary-text-content, #3a3a3a);
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.6px;
  text-transform: uppercase;
}
.lanEasySolar {
  color: var(--primary-text-headings, #2f3f50);
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.lanGlareText {
  color: var(--primary-text-content, #3a3a3a);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.lanTryNow {
  width: 429px !important;
  height: 44px !important;
  border-radius: 4px !important;
  color: var(--Primary-White, #fff) !important;
  background: var(--Primary-Button, #0984e3) !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 0px !important;
}
.lanImage1 {
  width: 100%;
  margin: auto;
}
@media screen and (max-width: 992px) {
  .lanImage1 {
    width: 320px;
    height: 320px;
  }
  .lanAppName {
    font-size: 16px;
    letter-spacing: 0.48px;
  }
  .lanEasySolar {
    font-size: 32px;
  }
  .lanGlareText {
    font-size: 16px;
  }
  .lanTryNow {
    width: 320px !important;
    height: 44px !important;
    font-size: 20px !important;
  }
}
.lanSolution {
  color: #2f3f50;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.lanFluentBox {
  border-radius: 12px;
  background: #f5f5f5;
  width: 145px;
  height: 145px;
}
.lanScrollHeading {
  margin: 20px 0px;
  color: #2f3f50;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
}
.lanScrolltext {
  color: #3a3a3a;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
}
.lanFootHeading {
  color: #2f3f50;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.lanFootText {
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.lanFooterText {
  color: #2f3f50;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
}
/* <OrientationPanel > */
.orientMainHead {
  color: var(--primary-text-headings, #2f3f50);
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.orientHeading {
  color: var(--primary-text-headings, #2f3f50);
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.orientSolarGlare {
  color: var(--primary-text-content, #3a3a3a);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 22px 0px;
}
.orientChecked {
  color: #3a3a3a;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding: 7px 0px;
}
.orient-Image1 {
  border-radius: 8px;
  width: 100%;
}
.callActionImage {
  width: 311px;
  height: 662px;
}
.CallAction h1 {
  color: var(--primary-text-headings, #2f3f50);
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.callActionDiv {
  height: 859px;
  background-color: #f5f5f5;
  opacity: 0.8;
  border-radius: 16px;
}
@media screen and (max-width: 992px) {
  .orientMainHead {
    font-size: 26px;
  }
  .orientHeading {
    margin-top: 15px;
    font-size: 20px;
  }
  .orientSolarGlare {
    padding: 18px 0px;
    font-size: 18px;
  }
  .orientChecked {
    font-size: 16px;
    padding: 5px 0px;
  }
}
@media screen and (max-width: 992px) {
  .callActionImage {
    width: 311px !important;
    height: 662px !important;
  }
  .callActionDiv {
    /* background-color: transparent; */
    width: 403px !important;
    height: 859px !important;
  }
}
@media screen and (max-width: 450px) {
  .CallAction {
    background: #f5f5f5;
  }
}
@media screen and (max-width: 767px) {
  .callActionImage {
    width: 320px;
    height: 400px;
    border-radius: 16px;
    opacity: 0.8;
  }
  .callActionDiv {
    background-color: transparent;
    height: auto;
    width: auto;
  }
}
@media screen and (max-width: 450px) {
  .CallAction {
    background: #f5f5f5;
  }
}
/* <Pricing> */
.pv-solarglare {
  color: #3a3a3a;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.6px;
  text-transform: uppercase;
}
.pricingHeding {
  color: #2f3f50;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.pricingText {
  color: #3a3a3a !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 300 !important;
  line-height: normal !important;
}
.pricingChecked {
  color: #3a3a3a;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.pricing15E {
  padding: 25px 0px;
}
.pricingTryNow {
  width: 100% !important;
  margin: 0px auto;
  margin-top: 50px;
  height: 44px !important;
  border-radius: 4px !important;
  color: var(--Primary-White, #fff) !important;
  background: var(--Primary-Button, #0984e3) !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 0px !important;
}
.ClientSlidehead {
  color: var(--primary-text-headings, #2f3f50);
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.client {
  color: var(--primary-text-content, #3a3a3a);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;
}
.clientName {
  color: var(--primary-text-headings, #2f3f50);
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.clientContent {
  color: var(--primary-text-content, #3a3a3a);
  /* text-align: center; */
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.clLeftArr {
  background-color: #f8fafd;
  cursor: pointer;
  border-radius: 50px;
}
.clRightArr {
  background-color: #f8fafd;
  cursor: pointer;
  border-radius: 50px;
}
@media screen and (max-width: 767px) {
  .ClientSlidehead {
    font-size: 26px;
  }
  .client {
    font-size: 16px;
  }
  .clientName {
    font-size: 20px;
  }
  .clientContent {
    font-size: 16px;
  }
  .cliArrow {
    width: 25.412px;
    height: 25.412px;
  }
  .cliArrow {
    width: 25.412px;
    height: 25.412px;
  }
}
/* <checkoutForm> */
.checkoutForm {
  width: 500px !important;
  margin-top: 50px;
  height: 100% !important;
}
.checkoutForm .payBtn {
  width: 100%;
  margin-top: 15px;
  padding: 6px 16px 6px 16px;
  background-color: #0984e3;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  color: #fff;
  display: flex;
  justify-content: center;
}
.checkoutForm .couponInput {
  height: 44px;
  width: 70%;
  border: 0.5px solid lightgray;
  border-radius: 4px;
  padding: 12px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
}
.checkoutForm .couponBtn{
  height: 44px;
  border-radius: 8px;
  background-color: #0984e3;
  font-size: 16px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 100px;

}
@media screen and (max-width: 550px) {
  .checkoutForm {
    width: 400px !important;
  }
}
.detectionHeight:focus {
  border: none !important;
  outline: none !important;
}
.glareNotFound {
  margin-top: 25px;
  text-align: center;
  font-size: 35px;
  font-weight: 500;
  line-height: 0px;
}
/* <securityDiv> */
.securityDiv label {
  font-size: 16px;
  font-weight: 600;
  color: #2f3f50;
  margin-bottom: 10px;
}
.securityDiv .Securityinput {
  width: 500px;
  height: 34px;
  border-radius: 2px;
  border: 0.5px solid #858585;
}
.securityDiv .Securityinput:focus {
  outline: none;
}

.securityDiv .securitySaveBtn {
  position: absolute;
  bottom: 55px;
  width: 500px;
  height: 34px;
  text-align: center;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  border-radius: 4px;
  background-color: #2f3f50;
}
.securityDiv .forgetPassword {
  color: #e31b1b;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  margin: 15px 0px;
  cursor: pointer;
}

@media screen and (max-width: 550px) {
  .securityDiv .securitySaveBtn {
    width: 300px;
  }
  .securityDiv .Securityinput {
    width: 300px;
  }
}

/* <profileSetting> */
.profileSettingSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 0px 50px 0px;
}
.profileSettingSection .profileSettingDiv {
  width: 500px;
}
.profileSettingSection .profileSettingDiv label span {
  font-size: 16px;
  font-weight: 600;
}
.profileSettingSection .profileSettingDiv .profileSettingInput {
  width: 218px;
  border: 0.5px solid #858585;
  border-radius: 2px;
  height: 34px;
}
.profileSettingSection .profileSettingDiv .companyNameInput {
  width: 500px;
  border: 0.5px solid #858585;
  border-radius: 2px;
  height: 34px;
}
.profileSettingSection
  .profileSettingDiv
  .profileSettingInput::-webkit-outer-spin-button,
.profileSettingSection
  .profileSettingDiv
  .profileSettingInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
.profileSettingSection .profileSettingDiv .profileSettingInput[type="number"] {
  -moz-appearance: textfield;
}

.profileSettingSection .profileSettingDiv .profileSettingInput:focus {
  outline: none;
}
.profileSettingSection .profileSetting-upload-container {
  width: 500px;
  height: 109px;
  text-align: center;
  position: relative;
  overflow: hidden;
  display: inline-block;
  border-radius: 4px;
  border: 1px solid #2f3f50;
  cursor: pointer;
}
.profileSettingSection .profileSettingAccordian {
  margin-top: 30px;
  width: 500px;
}

.profileSettingSection .profileSettingAccordian .accordion-button {
  outline: none !important;
  box-shadow: none !important;
  background-color: #f5f5f5 !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  border-radius: 4px !important;
}
.profileSettingSection .profileSettingAccordian .accordion-item {
  border: none !important;
}
.profileSettingSection .profileSettingAccordian .accordion-button {
  height: 30px !important;
}
.profileSettingSection .saveProfileSetting {
  width: 495px;
  text-align: center;
  background-color: #2f3f50;
  border-radius: 4px;
  height: 34px;
  font-size: 20px;
  font-weight: 400;
  margin-top: 40px;
  color: #fff;
}
.profileSettingSection .delprofile {
  color: #e31b1b;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  margin-top: 20px;
  text-decoration: none;
}
@media screen and (max-width: 550px) {
  .profileSettingSection .profileSettingDiv {
    width: 320px;
  }
  .profileSettingSection .profileSettingDiv .companyNameInput {
    width: 320px;
  }
  .profileSettingSection .profileSettingDiv .profileSettingInput {
    width: 152px;
  }
  .profileSettingSection .profileSetting-upload-container {
    width: 320px;
  }
  .profileSettingSection .profileSettingAccordian {
    width: 320px;
  }
  .profileSettingSection .saveProfileSetting {
    width: 295px;
  }
}
.deletePass {
  position: relative;
  top: 70px;
}
.deletePass span {
  font-size: 16px;
  font-weight: 600;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}
.deletePass input {
  width: 500px;
  height: 34px;
  border: 0.5px solid #858585;
  border-radius: 4px;
}
.deletePass input:focus {
  outline: none;
}
.deletePassbtn {
  position: absolute;
  bottom: 190px;
  text-align: center;
  width: 500px;
  height: 34px;
  border-radius: 4px;
  background-color: #e31b1b;
  color: #fff;
  border: none;
}
@media screen and (max-width: 550px) {
  .deletePassbtn {
    width: 300px;
  }
  .deletePass input {
    width: 300px;
  }
}

.deleteConfirmation {
  height: 100svh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.deleteConfirmation section {
  width: 500px;
  text-align: center;
}
.deleteConfirmation section .confirmBtn {
  width: 240px;
  height: 35px;
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  background-color: #0984e3;
  border-radius: 4px;
  border: none;
}
.deleteConfirmation section .cancelBtn {
  width: 240px;
  height: 35px;
  font-weight: 500;
  font-size: 18px;
  color: #000;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #0984e3;
}
.unpaidtextOverFlow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 170px;
}
@media screen and (max-width: 575px) {
  .unpaidtextOverFlow {
    overflow: visible;
    text-overflow: clip;
    white-space: normal;
    max-width: none;
  }
}
